<template>
<div>
  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Training">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Training Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.name"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row class="mb-0">
              <b-col md="12">

                <b-form-group
                  label="Uploaded By"
                  class="required"
                >


                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="url"
                      v-model="form.uploaded"
                      @change="resetData"
                    >
                      URL
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="file"
                      v-model="form.uploaded"
                      @change="resetData"
                    >
                      File
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>

              
            
            </b-row>

            <b-row v-if="form.uploaded == 'url'" class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Video URL (Youtube)"
                  class="required"
                >
                  <b-form-input
                    placeholder="ex- https://www.youtube.com/watch?v=fV-9IJfoGQE"
                    v-model="form.video_url"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row v-if="form.uploaded == 'file'" class="mb-2">
              <b-col md="12">

                <b-form-group
                  label="Upload File (mp4, pdf, png)"
                  class="mb-2 required">
                  <b-form-file
                    id="extension"
                    ref="file-input"
                    
                    accept=".mp4, .png, .pdf, .jpg, .jpeg"
                    @change="fileUpdate"
                  />
                </b-form-group>

              </b-col>
            </b-row>

            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'trainings' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile, BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';



export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    BFormFile,
    BBreadcrumb    
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        name : '',
        uploaded:'url',
        video_url:'',
        file:{
          name:'',
          image:null,
        }
      }
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/add-training'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'trainings' })
                });
                
            }
        });
      
    },

    fileUpdate(event){
      var input = event.target;
      var files = event.target.files
      //console.log(files);
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg' && image.type != 'application/mp4' && image.type != 'video/mp4' && image.type !='application/pdf') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .mp4, .pdf, .png, .jpeg, .jpg files only',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['file-input'].reset()

          } else {
              reader.onload = (e) => {
                  this.form.file.image = e.target.result;
                  this.form.file.name = image.name;
            }
          }
      }
    },
    resetData(){
      this.form.video_url = '';
      this.form.file = {
          name:'',
          image:null,
        }
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'trainings'},
        text: 'Trainings',
      },{
        to:{name:'trainings'},
        text:'Training Library',
      },{
        to:null,
        text:'Add Training'
      }];
      return item;
    }   

  },
  mounted(){
    
  }
}
</script>
